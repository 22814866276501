<template>
    <div
        v-if="user && !error"
        class="module__companies__members__edit"
    >
        <UserHeader
            :name="(user ? user.$data.name : null) || $t('dashboard.company.user.existing')"
            :show_tags="true"
            :manager="user ? user.$data.manager : false"
            :two_factor_enabled="user ? user.$data.two_factor_enabled : false"
        />

        <div class="module__companies__members__edit__content">
            <UserForm
                class="module__companies__members__edit__content__user-form"
                :user="user"
                :rules="rules"
                :has-local-changes="hasLocalChanges"
                :disabled="!canEditMember"
                @save="save"
                @data-changed="dataChanged"
            />

            <OtFormCollapse
                v-if="canManageTfa"
                :title="$t('dashboard.user.tfa.title')"
                :subtitle="$t('dashboard.user.tfa.subtitle')"
                header-icon="call"
                class="module__companies__members__edit__content__tfa"
                :button-open-label="$t('dashboard.common.action.open.text')"
                :button-close-label="$t('dashboard.common.action.close.text')"
            >
                <button
                    class="ot-button is-fullwidth is-danger"
                    type="button"
                    :title="!me?.$data.two_factor_enabled
                        ? $t('dashboard.user.tfa.action.disable.title_tfa_not_enabled')
                        : $t('dashboard.user.tfa.action.disable.title')"
                    :disabled="!me?.$data.two_factor_enabled"
                    @click="disableTfa()"
                >
                    <OtIcon
                        type="close"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.user.tfa.action.disable.text') }}
                </button>
            </OtFormCollapse>

            <template v-if="canRemoveFromCompany">
                <div class="ot-separator" />

                <div class="module__companies__members__edit__content__remove-member">
                    <h3>{{ $t('dashboard.user.remove.title') }}</h3>

                    <div class="ot-label">
                        {{ $t('dashboard.user.remove.description') }}
                    </div>

                    <button
                        class="ot-button is-danger"
                        type="button"
                        @click="removeMember"
                    >
                        <OtIcon
                            type="close"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.user.remove.button') }}
                    </button>
                </div>
            </template>
        </div>

        <OtPageFooter>
            <template #left>
                <button
                    class="ot-button is-dark"
                    type="button"
                    :title="$t('dashboard.common.action.back.title')"
                    @click="back()"
                >
                    <OtIcon
                        type="arrow-left"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.common.action.back.text') }}
                </button>
            </template>
        </OtPageFooter>
        <OtInlineModal ref="modalTfaConfirmation">
            <OTTFAConfirmation
                v-if="tfaTypesAvailable"
                :require-password="false"
                :blur-on-complete="false"
                :auto-submit="false"
                :error="tfaError"
                :tfa-types="tfaTypesAvailable"
                hide-logo
                hide-logout
                @submit="disableTfaAfterTfaConfirmation"
                @back="closeTfaConfirmation"
            />
        </OtInlineModal>
    </div>

    <ErrorView
        v-else-if="error"
        :label="error.message"
    />

    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { AuthClient, User } from '@openticket/lib-auth';
import { type DialogController, OtInlineModal } from '@openticket/vue-dashboard-components';
import urlJoin from 'url-join';
import {
    computed, reactive, ref, type UnwrapNestedRefs,
} from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router/composables';
import type { VueLocalization } from '@openticket/vue-localization';
import type VueNotifications from '@openticket/vue-notifications';
import type { TFAError, TFATypes } from '@openticket/vue-tfa-confirmation';
import { type AxiosError, isAxiosError } from 'axios';
import UserForm from '../../../../components/user/UserForm.vue';
import UserHeader from '../../../../components/user/UserHeader.vue';
import { injectOrFail } from '../../../../services/util';
import ErrorView from '../../../../components/ErrorView.vue';
import { useGenericErrorHandling } from '../../../../composables';
import {
    type ErrorDescription,
    type ErrorDescriptionAxiosError,
    isErrorDescriptionAxiosError,
} from '../../../../services/http/axios';
import type { Roles } from '../../../../plugins/types';

// DD-DASHBOARD-2715 - User roles can only be set on invitations. (And on the old dashboard).
// To change a user's role on the new dashboard they need to be kicked out of the company and
// re-invited with the correct role.

// DD-Auth-2719 - In order to guard against potential security issues (especially when a single user
// account is active in multiple whitelabels and one of them contains untrustworthy, privileged users)
// we restrict the editing of user properties to only the user themselves and global admins. Company
// managers and whitelabel admins can no longer directly set any of the user's properties (including
// password and email address). At no point does anybody other than the user know the user's password.

const route = useRoute();
const router = useRouter();
const { error, handleError } = useGenericErrorHandling();

const auth = injectOrFail<AuthClient>('auth');
const dialog = injectOrFail<DialogController>('dialog');
const localization = injectOrFail<VueLocalization>('localization');
const notifications = injectOrFail<VueNotifications>('notifications');
const roles = injectOrFail<UnwrapNestedRefs<Roles>>('roles');

const me = ref<User<AuthClient>>();
const user = ref<User<AuthClient>>();
const isMe = ref<boolean>();
const rules = ref<{ [p: string]: string[] }>({});
const hasLocalChanges = ref<boolean>(false);

const modalTfaConfirmation = ref<InstanceType<typeof OtInlineModal> | null>(null);
const tfaTypesAvailable = ref<TFATypes | null>(null);

const tfaError: TFAError = reactive({
    incorrectPassword: null,
    incorrectTFAInput: null,
});

const tfaData: {
    tfa_input: string | null;
    tfa_type: string | null;
} = reactive({
    tfa_input: null,
    tfa_type: null,
});

// DD-DASHBOARD-2717 - Only admins can disable a users' total tfa setup.
// DD-DASHBOARD-2719 - Only a user can disable a single tfa method.
// DD-DASHBOARD-271A - The option to disable all tfa methods for a user (admin only action) must always be
//  available, even if it seems that tfa is not set up.
//  This ensures a "safe" tfa reset if anything ever goes wrong.
// DD-DASHBOARD-271F - Admins can deleted anyone 2fa setup but since admins can't see other admins, their own
//  2fa setup cannot be hard deleted
const canManageTfa = computed<boolean>(() => roles.isSuperAdmin && !isMe.value);

// DD-DASHBOARD-271D - Managers and admins can view member profiles (of companies they have access to).
// Only admin can edit a member's profile.
const canEditMember = computed<boolean>(() => roles.isSuperAdmin && !isMe.value);

// DD-DASHBOARD-271E - Managers and admins can remove a user from a company
// Only if there are multiple users inside the company.
// TODO: user can not be removed if it is the only manager.
const canRemoveFromCompany = computed<boolean>(() => roles.isManager);

void (async () => {
    const userId: string = route.params.user;

    if (!userId) {
        console.error('User form error: User ID missing');

        // TODO translate and log error
        void router.push({ name: 'error', query: { reason: 'User ID missing' } });

        return;
    }

    try {
        rules.value = await auth.users.$rules();
        user.value = await auth.users.find(userId);
        me.value = await auth.users.me();
        isMe.value = me.value.id === user.value.id;
    } catch (e) {
        void handleError(e);
    }
})();

async function save(): Promise<void> {
    if (!canEditMember.value) {
        // TODO Proper error + logging
        console.error('Not allowed to save member data.');

        notifications.danger(localization.getI18n().t('dashboard.common.notification.save.fail'));

        throw new Error('Not allowed to save member data.');
    }

    if (!user.value) {
        // TODO Proper error + logging
        console.error('User not initialized yet!');

        notifications.danger(localization.getI18n().t('dashboard.common.notification.save.fail'));

        throw new Error('User not initialized yet!');
    }

    try {
        await auth.users.update(user.value);

        hasLocalChanges.value = false;

        notifications.success(localization.getI18n().t('dashboard.common.notification.save.success'));
    } catch (e) {
        // TODO Handle validation error(s).
        console.error('Update error: ', { e });

        notifications.danger(localization.getI18n().t('dashboard.common.notification.save.fail'));

        throw e;
    }
}

async function disableTfa(): Promise<void> {
    if (!user.value || !user.value.id) {
        // TODO Proper error + logging
        console.error('User not initialized yet!');

        // TODO @Peter save.fail is somewhat weird here...
        notifications.danger(localization.getI18n().t('dashboard.common.notification.save.fail'));

        throw new Error('User not initialized yet!');
    }

    // TODO Check for pending changes

    // confirm dialog both before and after tfa confirmation
    const confirm = await dialog?.confirm({
        title: localization.getI18n().t('dashboard.common.confirm.permanent.title') as string,
        description: localization.getI18n().t('dashboard.common.confirm.permanent.description') as string,
        type: 'is-danger',
    });

    if (confirm) {
        // TODO - @openticket/lib-auth
        try {
            await auth.$http.delete(
                urlJoin(auth.$path, 'admin', 'twofactor', 'users', user.value.id),
                { data: tfaData },
            );

            closeTfaConfirmation();
            notifications.success(localization.getI18n().t('dashboard.user.tfa.deletion.success'));
        } catch (e) {
            if (isAxiosError(e) && isErrorDescriptionAxiosError(e)) {
                // TODO A lot of potential errors are still missing.
                // This should instead be filled dynamically.
                if (isValidationAxiosError(e) && e.response.status === 406) {
                    tfaError.incorrectTFAInput = null;

                    if (e.response.data.error_description.tfa_type) {
                    // TODO - @openticket/lib-auth
                        tfaTypesAvailable.value = (await auth.$http.get<TFATypes>(urlJoin(auth.$path, 'twofactor'))).data;

                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        modalTfaConfirmation.value?.open();
                        return;
                    }

                    if (e.response.data.error_description.tfa_input) {
                        [ tfaError.incorrectTFAInput ] = e.response.data.error_description.tfa_input;
                    }

                    if (!modalTfaConfirmation.value?.opened) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        modalTfaConfirmation.value?.open();
                    }
                    return;
                }

                if (e.response.status === 403) {
                    // TODO Proper error and logging.
                    console.error('Failed to disable TFA', { e });

                    notifications.warning(e.response.data.error_description);

                    throw e;
                }
            }

            // TODO Proper error and logging.
            console.error('Failed to disable TFA', { e });

            notifications.warning(localization.getI18n().t('dashboard.user.tfa.deletion.warning'));

            throw e;
        } finally {
            user.value = await auth.users.find(user.value.id);
        }
    }
}

async function disableTfaAfterTfaConfirmation(tfaConfirmed: { input: string, type: string }): Promise<void> {
    tfaData.tfa_type = tfaConfirmed.type;
    tfaData.tfa_input = tfaConfirmed.input;

    await disableTfa();
}

function closeTfaConfirmation(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    modalTfaConfirmation.value?.close();

    tfaError.incorrectTFAInput = null;
    tfaData.tfa_input = null;
    tfaData.tfa_type = null;
}

type ValidationAxiosError = AxiosError<{
    error_description: { [key: string]: string[] };
}> & { response: { data: { error_description: { [key: string]: string[] }; } } };

function isValidationAxiosError(e: ErrorDescriptionAxiosError): e is ValidationAxiosError {
    const errorDescription: ErrorDescription['error_description'] = e.response.data.error_description;

    if (!errorDescription || typeof errorDescription === 'string') {
        return false;
    }

    return Object.entries(errorDescription).every(([ key, value ]) => {
        if (typeof key !== 'string') {
            return false;
        }

        return !!value && Array.isArray(value);
    });
}

async function removeMember(): Promise<void> {
    if (!user.value || !user.value.id) {
        // TODO Proper error + logging
        console.error('User not initialized yet!');

        // TODO @Peter save.fail is somewhat weird here...
        notifications.danger(localization.getI18n().t('dashboard.common.notification.save.fail'));

        throw new Error('User not initialized yet!');
    }

    // TODO Check for pending changes

    const confirm = await dialog?.confirm({
        title: localization.getI18n().t('dashboard.common.confirm.permanent.title') as string,
        description: localization.getI18n().t('dashboard.common.confirm.permanent.description') as string,
        type: 'is-danger',
    });

    if (confirm) {
        // TODO - @openticket/lib-auth
        try {
            await auth.$http.delete(urlJoin(auth.$path, 'users', user.value.id));

            // Ensure the latest token info is stored in local $info
            // TODO: replace with auth.withCompanyScope
            auth.setCompanyScope(null);
            await auth.$token.retrieveTokenInfo();

            notifications.success(localization.getI18n().t('dashboard.user.remove.success'));
        } catch (e) {
            // TODO Proper error and logging.
            console.error('Failed to remove member', { e });

            notifications.warning(localization.getI18n().t('dashboard.user.remove.warning'));

            throw e;
        }

        void router.push({
            name: isMe.value ? 'companies.list' : 'companies.edit.members',
            params: {
                company: route.params.company,
            },
        });
    }
}

async function back(): Promise<void> {
    await router.push({
        name: 'companies.edit.members',
        params: {
            company: route.params.company,
        },
    });
}

function dataChanged(changed: boolean): void {
    // TODO Maybe have this moved to the model itself???
    hasLocalChanges.value = changed;
}

onBeforeRouteUpdate(async (_to, _from, next) => {
    if (hasLocalChanges.value) {
        const confirm = await dialog.confirm({
            // TODO Below is quite ugly, we need a better solution to use translations in script setup (maybe composable?)
            title: localization.getI18n().t('dashboard.common.confirm.unsaved_changes.title') as string,
            description: localization.getI18n().t('dashboard.common.confirm.unsaved_changes.description') as string,
            type: 'is-danger',
        });

        if (!confirm) {
            return;
        }

        hasLocalChanges.value = false;
        next();
    }

    next();
});
</script>

<style lang="scss" scoped>
.module__companies__members__edit {
    padding: 0;

    &__content {
        margin-bottom: var(--ot-spacing-unit);

        .ot-separator {
            margin-top: var(--ot-spacing-3xl);
            margin-bottom: var(--ot-spacing-3xl);
        }

        &__user-form {
            margin-bottom: var(--ot-spacing-3xl);
        }

        &__tfa {
            margin-bottom: var(--ot-spacing-3xl);

            &__not-enabled{
                color: var(--ot-color-core-foreground-secondary);
            }
        }

        &__remove-member .ot-label {
            margin-bottom: var(--ot-spacing-lg);
        }
    }
}
</style>
