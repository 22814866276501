<template>
    <div
        class="input-price-vat"
    >
        <OtFormRow>
            <InputField
                :label="translations.price.label"
                :description="translations.price.description"
                :required="priceRules && priceRules.includes('required')"
                :optional="priceRules && priceRules.includes('optional')"
                :error="priceErrors"
                class="input-price-vat__price"
            >
                <OtInputText
                    v-if="isFree"
                    class="input input-price-vat__price--float"
                    :disabled="true"
                    :value="$t('dashboard.tickets.details.inputs.ticket_price.free')"
                />

                <OtInputFloat
                    v-else
                    :value="priceValue"
                    class="input input-price-vat__price--float"
                    min="0"
                    :prefix="currency"
                    :decimals="2"
                    :factor="100"
                    data-testid="input-price-price"
                    @input="emitPriceChange"
                />

                <OtInputToggle
                    data-testid="input-price-vat-isfree"
                    class="input-price-vat__price--isfree"
                    :label="$t('dashboard.common.free')"
                    :value="isFree"
                    @input="handleIsFreeToggle"
                />
            </InputField>
        </OtFormRow>
        <OtFormRow>
            <InputField
                class="input-price-vat__vat"
                data-testid="input-price-vat"
                :label="translations.vat.label"
                :description="translations.vat.description"
            >
                <OtInputSelectBar
                    :value="vatPercentage"
                    :options="vatOptions"
                    class="input-price-vat__vat--select"
                    :disabled="isFree"
                    @input="updateVatPercentage"
                >
                    <template #none>
                        {{ vatOptions['none'] }}%
                    </template>
                    <template #reduced>
                        {{ vatOptions['reduced'] }}%
                    </template>
                    <template #normal>
                        {{ vatOptions['normal'] }}%
                    </template>
                    <template #other>
                        {{ vatOptions['other'] }}
                    </template>
                </OtInputSelectBar>
                <OtInputFloat
                    v-if="vatPercentage === 'other'"
                    :value="vatValue"
                    class="input input-price-vat__vat--float"
                    :prefix="$t('dashboard.events.wizard.steps.tickets.upsert.form.vat.input.prefix')"
                    suffix="%"
                    :disabled="isFree"
                    :min="0"
                    @input="emitVatChange"
                />
            </InputField>
        </OtFormRow>
    </div>
</template>

<script setup lang="ts">
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import {
    computed, onMounted, ref,
} from 'vue';
import type { Context } from '../../services/context';
import type { FormErrors } from '../../composables/forms/types';
import { injectOrFail } from '../../services/util';
import InputField from './InputField.vue';

// TODO Move these hardcoded values to the backend where the values can be updated when necessary
type VatCountries = 'BE' | 'CH' | 'DE' | 'ES' | 'FR' | 'GT' | 'IE' | 'NL' | 'TR' | 'UK' | 'US';
const VAT_RATES: { [key in VatCountries ]: CountryVatRates } = {
    BE: { reduced: 6, normal: 21 },
    CH: { reduced: 2.5, normal: 7.7 },
    DE: { reduced: 7, normal: 19 },
    ES: { reduced: 10, normal: 21 },
    FR: { reduced: 5.5, normal: 20 },
    GT: { reduced: 5, normal: 12 },
    IE: { reduced: 13.5, normal: 23 },
    NL: { reduced: 9, normal: 21 },
    TR: { reduced: 10, normal: 20 },
    UK: { reduced: 5, normal: 20 },
    US: {},
};

type CountryVatRates = { reduced?: number, normal?: number };
type StandardVatRates = { none: number, other: TranslateResult };
type VatRates = CountryVatRates & StandardVatRates;

type Props = {
    translations: {
        price: { label: TranslateResult, description?: TranslateResult }
        vat: { label: TranslateResult, description?: TranslateResult }
    }
    currency: string
    priceValue: number
    priceErrors: FormErrors
    priceRules?: string[]
    vatValue: number
    vatErrors: FormErrors
}

type Emits = {
    (e: 'vat-change', value: number): void,
    (e: 'price-change', value: number): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const localization = injectOrFail<VueLocalization>('localization');
const context = injectOrFail<Context>('context');

const isFree = ref<boolean>(props.priceValue === 0);
const vatPercentage = ref('0');

const vatOptions = computed<VatRates>(() => {
    const standarVatOptions = {
        none: 0,
        other: localization.getI18n().t('dashboard.events.wizard.steps.tickets.upsert.form.vat.other'),
    };

    if (!context.isCompanyContext()) {
        return standarVatOptions;
    }

    const companyLocation = context.company.model.$data.country ?? localization.locale.territory;
    const vatRates = VAT_RATES[companyLocation as VatCountries];

    if (!vatRates) {
        return standarVatOptions;
    }

    return {
        none: 0,
        ...vatRates,
        other: localization.getI18n().t('dashboard.events.wizard.steps.tickets.upsert.form.vat.other'),
    };
});

const priceErrors = computed<FormErrors>(() => {
    const errors: FormErrors = [];

    if (props.priceErrors) {
        errors.push(...props.priceErrors);
    }

    if (props.vatErrors) {
        const map = props.vatErrors.map((item) => ({
            ...item,
            customAttribute: localization.getI18n().t('dashboard.events.wizard.steps.tickets.upsert.form.price.vat'),
        }));

        errors.push(...map);
    }

    return errors;
});

onMounted(() => {
    const vat = props.vatValue;
    const vatRate: keyof VatRates | undefined = (Object.keys(vatOptions.value) as Array<keyof VatRates>)
        .find((key: keyof VatRates) => vatOptions.value[key] === vat);

    updateVatPercentage(vatRate || 'other');
});

function updateVatPercentage(value: keyof VatRates) {
    const newValue = vatOptions.value[value];
    vatPercentage.value = value;

    if (value !== 'other' && typeof newValue === 'number') {
        emitVatChange(newValue);
    }
}

function handleIsFreeToggle() {
    isFree.value = !isFree.value;

    if (isFree.value) {
        emitPriceChange(0);
        updateVatPercentage('none');
    }
}

function emitPriceChange(value: number) {
    emit('price-change', value);
}

function emitVatChange(value: number) {
    emit('vat-change', value);
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixins.scss";

.input-price-vat {
    &__price, &__vat {
        @include breakpoint(mob) {
            &:deep(.input-field__content) {
                align-items: flex-start;
                flex-direction: column;
                gap: var(--ot-spacing-sm);
            }

            &--float {
                width: unset;
            }
        }
    }

    &__vat--select {
        max-width: max-content;
    }
}
</style>
