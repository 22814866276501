<template>
    <ErrorView
        v-if="error"
        :label="error.message"
    />

    <OtSpinner v-else-if="!shops" />

    <div
        v-else
        class="modules__shops__list"
    >
        <OtDataGrid
            class="module__shops__list__grid"
            type="tiles"
            :pagination="shops"
            :columns="columns"
            show-switcher
            show-controls="always"
            :empty-title="$t('dashboard.shops.list.empty.title')"
            :empty-message="$t('dashboard.shops.list.empty.message')"
            :empty-message-active-filter="$t('dashboard.common.filters.not_found')"
            :loading-message="$t('dashboard.shops.list.header.loading')"
        >
            <template #list-header="{ typeSwitcher }">
                <OtListHeader
                    :pagination="shops"
                    searchable
                    :search-key="'name'"
                    :search-placeholder="$t('dashboard.shops.list.header.search.placeholder')"
                    :title="$t('dashboard.document_title.shops.list')"
                >
                    <template #filters>
                        <OtFilters
                            :categories-to-hide="filterCategoriesToHide"
                            :labels="$t('models.shop')"
                            :pagination="shops"
                            :translations="$t('dashboard.common.filters')"
                            :view-filter="{ label: companyViewFilterSlug }"
                        />
                    </template>

                    <template #actions-top>
                        <button
                            :title="$t('dashboard.shops.list.new.title')"
                            class="ot-button"
                            data-testid="shop-list-new-button"
                            @click="companySelector?.open()"
                        >
                            <OtIcon
                                type="plus"
                                class="ot-button-icon--left"
                            />
                            {{ $t('dashboard.shops.list.new.text') }}
                        </button>
                    </template>

                    <template #actions-bottom>
                        <component :is="typeSwitcher" />
                    </template>
                </OtListHeader>
            </template>

            <template #column__-data__company_id="{ record, value }">
                <a
                    class="module__shops__list-record__company-name ot-link ot-clickable"
                    role="button"
                    tabindex="0"
                    @click="goToCompanyContext(record.$data.company_id)"
                    @keydown.space="goToCompanyContext(record.$data.company_id)"
                    @keydown.enter="goToCompanyContext(record.$data.company_id)"
                >{{ companyName(value) }}</a>
            </template>

            <template #tile="{ record }">
                <ShopsListRecord
                    :record="record"
                    @context-switch="goToCompanyContext"
                />
            </template>

            <template #column__url="{ record }">
                <ShopUrl :record="record" />
            </template>

            <template #actions="{ record }">
                <OtTileActions
                    :buttons="
                        [{
                            title: $t('dashboard.shops.list.edit.title', { shop: record.$data.name }),
                            text: $t('dashboard.shops.list.edit.text', { shop: record.$data.name }),
                            icon: 'carrot-right',
                            classes: 'is-outline is-small',
                            clickHandler: () => goToShopPage(record.$data.guid)
                        }]"
                />
            </template>
        </OtDataGrid>

        <!--<div class="shop-list__content" v-if="shops && !error">-->
        <!--    <div class="shop-list__filters">-->
        <!--        <div class="shop-list__filters__search">-->
        <!--            <ot-input-field :label="$t('dashboard.shop.search.label')">-->
        <!--                <ot-input-->
        <!--                    type="search"-->
        <!--                    :placeholder="$t('dashboard.shop.search.placeholder')"-->
        <!--                    v-model="searchString"-->
        <!--                ></ot-input>-->
        <!--            </ot-input-field>-->
        <!--        </div>-->
        <!--    </div>-->

        <CompanySelector
            ref="companySelector"
            :create-model-label="$t('dashboard.shops.model_name').toString().toLocaleLowerCase()"
            data-testid="shop-list-company-selector"
            :update-context-on-select="false"
            @selected="openCreateShopModal"
        />

        <ShopCreateModal
            v-if="formModal.isOpen && shopCreateCompanyGuid"
            :company-guid="shopCreateCompanyGuid"
            :modal="formModal"
            @saved="onNewShopSaved"
        />
    </div>
</template>

<script lang="ts" setup>
import type { ManagementClient } from '@openticket/lib-management';
import {
    computed, reactive, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import type { Column } from '@openticket/vue-dashboard-components';
import type { AuthClient, CompanyRaw } from '@openticket/lib-auth';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import type { Context } from '../../../services/context';
import { injectOrFail } from '../../../services/util';
import ErrorView from '../../../components/ErrorView.vue';
import ShopsListRecord from '../components/ShopsListRecord.vue';
import ShopUrl from '../components/ShopUrl.vue';
import { useGenericErrorHandling, useRouterPagination } from '../../../composables';
import { useFormModal } from '../../../composables/forms';
import ShopCreateModal from '../components/ShopCreateModal.vue';
import CompanySelector from '../../../components/CompanySelector.vue';

const { error } = useGenericErrorHandling();
const router = useRouter();

const formModal = reactive(useFormModal());

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');
const localization = injectOrFail<VueLocalization>('localization');
const management = injectOrFail<ManagementClient>('management');

const companies = ref<CompanyRaw[] | null>(null);
const companySelector = ref<InstanceType<typeof CompanySelector> | null>(null);
const shopCreateCompanyGuid = ref<string | null>(null);

const { list: shops, setPagination } = useRouterPagination(management.shops, { perPage: 10 });
const companyViewFilterSlug = computed<string>(() => (context.isCompanyContext()
    ? context.company.name
    : localization.getI18n().t('dashboard.sidebar.context_filter.company.all_companies') as string
));

const columns: Array<Omit<Column, 'label'> & { label: TranslateResult }> = [
    {
        key: [ '$data', 'name' ],
        type: 'string',
        label: $t('models.shop.attributes.name.label'),
        hideInSimpleTile: true,
    },
    {
        key: [ '$data', 'company_id' ],
        type: 'custom',
        label: $t('models.company.name.single'),
    },
    {
        key: [ 'url' ],
        type: 'string',
        label: $t('dashboard.shops.list.column.url'),
        simpleTileColumnSize: 'large',
    },
];

const filterCategoriesToHide = [
    'company_terms',
    'created_at',
    'custom_redirect',
    'event_selection',
    'facebook_auto_attend',
    'facebook_page_url',
    'from_email',
    'from_name',
    'google_tag',
    'greedy_date_selection',
    'updated_at',
];

watch(() => context.company, (newValue, oldValue) => {
    if (!context.isShopContext() && newValue?.id !== oldValue?.id) {
        void setPagination(management.shops);
    }
});

void (async () => {
    // Retrieve companies from the token, to prevent fetching the companies
    // list. This assumes the user is only able to access companies
    // that are saved within the token.
    const token = await auth.$token.$info;

    if (token?.companies) {
        companies.value = token.companies;
    }
})();

function goToShopPage(shop: string): void {
    void router.push({
        name: 'shops.home',
        params: { shop },
    });
}
async function goToCompanyContext(companyId: string) {
    await context.updateContext('company', { company: companyId });
}

function companyName(companyId: string): string | null {
    const company = companies?.value?.find((singleCompany) => singleCompany.guid === companyId);

    return company!.name;
}

function onNewShopSaved(modelGuid: string | null) {
    if (modelGuid) {
        void router.push({
            name: 'shops.home',
            params: { shop: modelGuid },
        });
    }
}

function openCreateShopModal(companyGuid: string): void {
    shopCreateCompanyGuid.value = companyGuid;
    formModal.open();
}

//    searchString: string | null = null;
//    shopImg: string = shopImg;
//    // @Watch('searchString')
//    // private onSearch(): void {
//    //     const query = { ...this.$route.query || {} };
//    //
//    //     if (this.searchString && this.searchString.length) {
//    //         void this.shops.addFilter('name', this.searchString);
//    //         query.name = this.searchString;
//    //     } else {
//    //         void this.shops.removeFilter('name');
//    //         delete query.name;
//    //     }
//    //
//    //     void this.$router.replace({
//    //         path: this.$route.path,
//    //         query,
//    //     });
//    // }

// TODO: Remove when upgraded to Vue 3
function $t(slug: string, values?: Record<string, unknown>) {
    return localization.getI18n().t(slug, values);
}
</script>

<style lang="scss" scoped>
.module__shops__list {
    padding-top: var(--ot-spacing-default);

    &-record__company-name::after {
      display: none;
    }

    &__filters {
        display: flex;
        flex-direction: row;
        gap: var(--ot-spacing-default);

        &__select {
            flex: 1;
            margin-right: 0.5rem;
        }

        &__search {
            flex: 1;
            max-width: 50%;
        }
    }
}
</style>
