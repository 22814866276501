<template>
    <div class="module__addon-products__list">
        <OtDataGrid
            class="module__addon-products__list"
            type="tiles"
            :pagination="addonProducts"
            :columns="columns"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="addonProducts"
                    searchable
                    search-key="name"
                    :search-placeholder="$t('common.placeholder.search')"
                    :title="$t('dashboard.document_title.addon_products.list')"
                >
                    <!-- TODO Enable when filter values that need to be hidden are known
                    <template #filters>
                        <OtFilters
                            :categories-to-hide="filterCategoriesToHide"
                            :labels="$t('models.products')"
                            :pagination="addonProducts"
                            :translations="$t('dashboard.common.filters')"
                            :view-filter="{ label: context.event?.name }"
                        />
                    </template> -->

                    <template #actions-top>
                        <button
                            class="ot-button"
                            :title="$t('dashboard.addon_products.list.header.new.title')"
                            data-testid="addon-product-create-btn"
                            @click="formModal.open()"
                        >
                            <OtIcon
                                type="plus"
                                class="ot-button-icon--left"
                            />
                            {{ $t('dashboard.addon_products.list.header.new.text') }}
                        </button>
                    </template>
                </OtListHeader>
            </template>

            <template #tile="{ record }">
                <OtSimpleTile
                    class="module__addon-products__list__grid__tile"
                    :title="record.$data.name"
                    :record="record"
                    clickable
                    @click="() => $router.push({
                        name: 'addon-products.edit',
                        params: { addonProduct: record.$data.guid },
                    })"
                >
                    <template #column__-data__price="{ value }">
                        {{ value === 0 ?
                            $t('dashboard.common.free') :
                            $l.currency(value, currency) }}
                    </template>
                    <template #column__-data__available_stock="{ record: columnRecord , value }">
                        <FractionColumn
                            :numerator="columnRecord.$data.sold_count || 0"
                            :denominator="value"
                        />
                    </template>
                </OtSimpleTile>
            </template>

            <template #column__-data__available_stock="{ record, value }">
                <FractionColumn
                    :numerator="record.$data.sold_count || 0"
                    :denominator="value"
                />
            </template>
        </OtDataGrid>

        <AddonProductCreateModal
            v-if="formModal.isOpen"
            :modal="formModal"
            @submit="() => addonProducts?.loadPage()"
        />
    </div>
</template>

<script setup lang="ts">
import {
    ColumnTypes, OtDataGrid, OtSimpleTile, type Column,
} from '@openticket/vue-dashboard-components';
import { reactive, watch } from 'vue';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import { useFormModal } from '../../../composables/forms';
import FractionColumn from '../../../components/columns/FractionColumn.vue';
import { useRouterPagination } from '../../../composables';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import AddonProductCreateModal from '../components/AddonProductCreateModal.vue';

const context = injectOrFail<Context>('context');
const localization = injectOrFail<VueLocalization>('localization');

if (!context.isEventContext()) {
    throw new Error('Invalid context!');
}

const { currency } = context.event.model.$data;
const { list: addonProducts, setPagination } = useRouterPagination(context.event.model.products, { perPage: 5 });
const formModal = reactive(useFormModal());

const columns: Array<Omit<Column, 'label'> & { label: TranslateResult }> = [
    {
        key: [ '$data', 'price' ],
        type: ColumnTypes.number,
        label: $t('models.product.attributes.price.label'),
        simpleTileColumnSize: 'default',
    },
    {
        key: [ '$data', 'available_stock' ],
        type: ColumnTypes.number,
        label: $t('models.product.attributes.available_stock.label'),
        simpleTileColumnSize: 'default',
    },
];

watch(() => context.event?.id, (value) => {
    if (value !== null && value !== undefined) {
        void setPagination(context.event.model.products);
    }
});

// TODO: Remove when upgraded to Vue 3
function $t(slug: string, values?: Record<string, unknown>) {
    return localization.getI18n().t(slug, values);
}
</script>

<style scoped lang="scss">
.module__addon-products__list {
    display: flex;
    flex-direction: column;
    gap: var(--ot-spacing-2xl);

    &__grid {
        min-height: 25rem;
    }
}
</style>
