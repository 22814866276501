<template>
    <div class="addon-product-update__linked-tickets">
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <OtDataGrid
            class="addon-product-update__linked-tickets__grid"
            type="table"
            :columns="columns"
            :pagination="linkedTickets"
            hide-header
            :empty-title="$t('dashboard.addon_products.linked_tickets.list.empty.title')"
        >
            <template #list-header>
                <OtListHeader
                    :pagination="linkedTickets"
                    :title="$t('dashboard.addon_products.linked_tickets.list.header.title')"
                />
            </template>
            <template #actions="{ record }">
                <RouterLink
                    class="ot-button is-tiny is-outline ot-px[sm] addon-product-update__linked-tickets__grid__item__delete"
                    data-testid="addon-product-linked-tickets-navigation"
                    :to="{ name: 'tickets.home', params: { ticket: record.id }}"
                >
                    {{ $t('dashboard.addon_products.linked_tickets.list.ticket.navigation.text') }}
                    <OtIcon
                        type="carrot-right"
                        size="small"
                    />
                </RouterLink>
            </template>
        </OtDataGrid>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import type {
    Ticket,
    ManagementClient,
    Product,
} from '@openticket/lib-management';
import type { VueLocalization } from '@openticket/vue-localization';
import type { Pagination } from '@openticket/lib-crud';
import {
    ColumnTypes, OtDataGrid, type Column,
} from '@openticket/vue-dashboard-components';
import { RouterLink } from 'vue-router';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';
import ErrorView from '../../../components/ErrorView.vue';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');
const localization = injectOrFail<VueLocalization>('localization');

const { error } = useGenericErrorHandling();

if (!context.isAddonProductContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const columns: Column[] = [
    {
        key: [ '$data', 'name' ],
        type: ColumnTypes.string,
        label: $t('Name'),
        simpleTileColumnSize: 'default',
    },
];

// Type adjusted copy of the contexts addonproduct to have tickets relation
const addonProduct = management.products.$factory(context.addonProduct.model.$raw);
const linkedTickets = ref<Pagination<Ticket<Product<ManagementClient>>>>();

void loadTickets();

async function loadTickets() {
    linkedTickets.value = addonProduct.tickets.list();
    await linkedTickets.value?.initialization;
}

// TODO: Remove when upgraded to Vue 3
function $t(slug: string, values?: Record<string, unknown>) {
    return localization.getI18n().t(slug, values);
}
</script>
