<template>
    <OtInlineModal
        ref="companySelector"
        :width="400"
        @close="$emit('close')"
    >
        <div
            class="company-selector ot-card ot-p[lg]"
            data-testid="company-selector"
        >
            <i18n
                v-if="createModelLabel"
                class="company-selector__title"
                tag="h2"
                path="dashboard.company.selector.title_model"
            >
                <span class="company-selector__title__model">
                    {{ createModelLabel }}
                </span>
            </i18n>

            <h2
                v-else
                class="company-selector__title"
            >
                {{ $t('dashboard.company.selector.title') }}
            </h2>

            <OtInput
                type="search"
                :placeholder="$t('dashboard.company.selector.search')"
                :value="searchValue"
                class="company-selector__search"
                @input="handleSearch"
            />

            <div class="company-selector__content">
                <OtSpinner v-if="!filteredList || loading" />

                <span
                    v-else-if="!filteredList.length"
                    class="company-selector__not-found ot-text-body"
                >
                    {{ $t('dashboard.company.selector.not_found') }}
                </span>

                <ul
                    v-if="!loading"
                    class="company-selector__content__list"
                >
                    <li
                        v-for="item of filteredList"
                        :key="item.guid || ''"
                        :title="item.name"
                        class="company-selector__content__list__item ot-clickable"
                        data-testid="company-selector-item"
                        role="button"
                        tabindex="0"
                        @click="selectOption(item.guid)"
                        @keydown.space="selectOption(item.guid)"
                        @keydown.enter="selectOption(item.guid)"
                    >
                        <div class="company-selector__content__list__item-label text-body-strong">
                            <h4>{{ item.name }}</h4>
                        </div>
                        <OtIcon
                            type="carrot-right"
                            size="small"
                        />
                    </li>
                    <div
                        v-if="lastPage > 1"
                        class="company-selector__content__list__controls"
                    >
                        <div
                            class="company-selector__content__list__controls__icon"
                            :disabled="currentPage < 2 || lastPage < 2"
                            role="button"
                            tabindex="0"
                            @click="shiftPage(-1)"
                            @keydown.space="shiftPage(-1)"
                            @keydown.enter="shiftPage(-1)"
                        >
                            <OtIcon
                                type="carrot-left"
                                size="small"
                            />
                        </div>

                        <span class="ot-text-small">
                            {{ currentPage }} / {{ lastPage }}
                        </span>

                        <div
                            class="company-selector__content__list__controls__icon"
                            :disabled="currentPage >= lastPage || lastPage < 2"
                            role="button"
                            tabindex="0"
                            @keydown.space="shiftPage(1)"
                            @click="shiftPage(1)"
                            @keydown.enter="shiftPage(1)"
                        >
                            <OtIcon
                                type="carrot-right"
                                size="small"
                            />
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    </OtInlineModal>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
    type Company,
    type AuthClient, type CompanyRaw,
} from '@openticket/lib-auth';
import { OtInlineModal } from '@openticket/vue-dashboard-components';
import type { TranslateResult } from '@openticket/vue-localization';
import type { Pagination, PaginationResponse } from '@openticket/lib-crud';
import { injectOrFail } from '../services/util';
import type { Context, ContextType } from '../services/context';
import { useDebounce } from '../composables/useDebounce';
import { useGenericErrorHandling } from '../composables';
import type { Roles } from '../plugins/types';

type Props = {
    createModelLabel?: string | TranslateResult,
    updateContextOnSelect?: boolean;
}

type Emits = {
    (e: 'close'): void,
    (e: 'selected', companyGuid: string, previousContext: ContextType): void,
}

const props = withDefaults(defineProps<Props>(), {
    createModelLabel: undefined,
    updateContextOnSelect: true,
});
const emit = defineEmits<Emits>();

const debounce = useDebounce();
const { handleError } = useGenericErrorHandling();

const auth = injectOrFail<AuthClient>('auth');
const context = injectOrFail<Context>('context');
const roles = injectOrFail<Roles>('roles');

const companyPagination = ref<Pagination<Company<AuthClient>> | null>(null);
const companies = ref<PaginationResponse<Company<AuthClient>> | null>(null);

const companySelector = ref<InstanceType<typeof OtInlineModal> | null>(null);
const searchValue = ref<string>('');
const loading = ref(0);

const filteredList = computed<CompanyRaw[] | null>(() => {
    if (!companies.value) {
        return [];
    }

    return companies.value.data.map((item) => item.$data);
});

const currentPage = computed<number>(() => (!companies.value ? 1 : companies.value.current_page) ?? 1);
const lastPage = computed<number>(() => (!companies.value ? 1 : companies.value.last_page) ?? 1);

async function init() {
    try {
        companyPagination.value = auth.companies.list(5);
        await setCompanyRecords(true);
    } catch (e) {
        handleError(e);
    }
}

async function setCompanyRecords(useTokenCompanies: boolean, searchFilter?: string) {
    try {
        loading.value++;

        if (!companyPagination.value) {
            return;
        }

        // Companyscope has to be set to null otherwise it will only search companies added to the request header.
        let companyScope = null;

        if (useTokenCompanies) {
            const info = await auth.$token.$info;
            const tokenCompanies = info?.companies.map((company) => company.guid);

            // Add the current company from the context to the Company header as well. Needed for whitelabel admins.
            if (roles.isWhitelabelAdmin && context.company?.id && !tokenCompanies?.includes(context.company.id)) {
                tokenCompanies?.push(context.company.id);
            }

            companyScope = tokenCompanies?.join(',') ?? '';
        }

        await auth.withCompanyScope(companyScope, async () => {
            companies.value = searchFilter
                ? await companyPagination.value!.setFilter('name', 'contains', searchFilter ?? '')
                : await companyPagination.value!.initialization;
        });
    } catch (error) {
        handleError(error);
    } finally {
        loading.value--;
    }
}

async function selectOption(guid: string | undefined) {
    if (!guid) {
        // TODO: Proper error handling and logging
        throw Error('No guid given');
    }

    const previousContext = context.type;

    if (props.updateContextOnSelect) {
        await context.updateContext('company', { company: guid });
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    companySelector.value?.close();

    emit('selected', guid, previousContext);
}

function handleSearch(input: string) {
    searchValue.value = input;

    if (input === '') {
        // Reset to tokenCompanies
        void setCompanyRecords(true);
        return;
    }

    debounce(input, () => {
        // Search with company header * to be able to search all accessible companies
        void setCompanyRecords(false, input);
    });
}

async function shiftPage(shift: number): Promise<void> {
    if (!companies.value || !companyPagination.value) {
        return;
    }

    try {
        loading.value++;
        companies.value = await companyPagination.value.loadPage(
            Math.max(1, currentPage.value + shift),
        );
    } catch (e) {
        handleError(e);
    } finally {
        loading.value--;
    }
}

function open() {
    if (context.isCompanyContext()) {
        emit('selected', context.company.id, context.type);
        return;
    }
    void init();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    companySelector.value?.open();
}

defineExpose({
    open,
});
</script>

<style scoped lang="scss">
@import "../assets/scss/mixins.scss";

.company-selector {
    border-radius: var(--ot-input-radius);
    display: flex;
    flex-direction: column;
    gap: var(--ot-spacing-sm);

    &__title {
        margin: 0;
        flex: 0;

        &__model {
            color: var(--ot-color-core-brand);
        }
    }

    &__not-found {
        min-width: 100%;
        text-align: center;
        color: var(--ot-color-core-light-accent-primary);
    }

    &__content {
        height: 18rem;

        &__list {
            display: flex;
            flex-direction: column;
            list-style: none;

            &__item {
                display: flex;
                align-items: center;
                padding: var(--ot-spacing-sm) 0;
                border-top: 1px solid var(--ot-color-core-accent-tertiary);

                &-label {
                    @include breakpoint(mob) {
                        max-width: 75vw;
                    }

                    max-width: 95%;
                    flex: 1;

                    h4 {
                        min-width: 0;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                &:first-child  {
                    border-top-color: #00000000;
                }
            }

            &__controls {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--ot-spacing-xs);
                border-top: 1px solid var(--ot-color-core-accent-tertiary);
                padding-top: var(--ot-spacing-sm);

                &__icon {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &[disabled], &.disabled {
                        color: var(--ot-color-core-accent-primary);
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
</style>
