<template>
    <FormWrapper
        class="ticket-update"
        :form="ticketForm"
        back-route="tickets.list"
    >
        <template #header-title>
            <div class="ticket-update__header-title">
                <h1>{{ headerTitle }}</h1>

                <OtFlair
                    v-if="ticketForm.model.$data.status_overrule === 'sold_out'"
                    icon="check"
                    type="is-black"
                >
                    {{ String(
                        $t('dashboard.tickets.sale_settings.inputs.ticket_availability.options.sold_out')
                    ).toLocaleUpperCase() }}
                </OtFlair>
            </div>
        </template>

        <template #secondary-actions>
            <RevisionModal
                :id="context.ticket?.id || ''"
                ref="revisionsModalRef"
                type="ticket"
                :model-name="context.ticket?.name || ''"
            />
        </template>

        <VerticalTabs>
            <template #default="{ selected }">
                <VerticalTabsItem
                    :label="$t('dashboard.tickets.details.route_title')"
                    :route="{ name: 'tickets.edit.details' }"
                    :selected="selected"
                >
                    <TicketDetailsForm
                        ref="ticketDetailsFormRef"
                        :form="ticketForm"
                    />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.tickets.sale_settings.route_title')"
                    :route="{ name: 'tickets.edit.sale_settings' }"
                    :selected="selected"
                >
                    <TicketSaleSettingsForm :form="ticketForm" />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.tickets.ticket_design.route_title')"
                    :route="{ name: 'tickets.edit.ticket_design' }"
                    :selected="selected"
                    disabled
                >
                    <TicketDesign />
                </VerticalTabsItem>
                <VerticalTabsItem
                    :label="$t('dashboard.tickets.visitor_data.route_title')"
                    :route="{ name: 'tickets.edit.visitor_data' }"
                    :selected="selected"
                    disabled
                >
                    <TicketVisitorData />
                </VerticalTabsItem>
                <!-- TODO: uncomment when addon products goes live
                <VerticalTabsItem
                    :label="$t('dashboard.tickets.addon_products.route_title')"
                    :route="{ name: 'tickets.edit.addon_products' }"
                    :selected="selected"
                >
                    <TicketAddonProducts />
                </VerticalTabsItem> -->
            </template>
        </VerticalTabs>
    </FormWrapper>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';
import type {
    Event,
    ManagementClient,
    Ticket,
} from '@openticket/lib-management';
import { useUpdateForm } from '../../../composables/forms';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import VerticalTabs from '../../../components/verticaltabs/VerticalTabs.vue';
import VerticalTabsItem from '../../../components/verticaltabs/VerticalTabsItem.vue';
import FormWrapper from '../../../components/form/FormWrapperNew.vue';
import TicketDetailsForm from '../components/forms/TicketDetailsForm.vue';
import TicketSaleSettingsForm from '../components/forms/TicketSaleSettingsForm.vue';
import TicketDesign from '../components/TicketDesign.vue';
import TicketVisitorData from '../components/TicketVisitorData.vue';
import RevisionModal from '../../../components/revisions/RevisionModal.vue';

// TODO This and the context type check will probably be combined when creating a context composable
const context = injectOrFail<Context>('context');

if (!context.isTicketContext()) {
    // TODO Properly log error & localise reason.
    throw Error('Invalid context');
}

const revisionsModalRef = ref<InstanceType<typeof RevisionModal> | null>(null);
const ticketDetailsFormRef = ref<InstanceType<typeof TicketDetailsForm> | null>(null);
const ticketForm = reactive(
    useUpdateForm<Ticket<ManagementClient>, Event<ManagementClient>>(context.ticket.model, context.event.model.tickets, {
        async onSubmit() {
            headerTitle.value = ticketForm.getModelName();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await revisionsModalRef.value?.updateRevisions();
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            await ticketDetailsFormRef.value?.linkAccessMoments();
        },
    }),
);

const headerTitle = ref<string>(ticketForm.getModelName());
</script>

<style lang="scss" scoped>
.ticket-update {
    &__header-title {
        display: flex;
        align-items: center;
        gap: var(--ot-spacing-xs);
    }
}
</style>
